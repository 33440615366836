/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useStaticQuery, graphql } from 'gatsby';
import {
  GatsbySeo,
  BlogPostJsonLd,
  SocialProfileJsonLd,
} from 'gatsby-plugin-next-seo';

interface SEOProps {
  description?: string;
  title?: string;
  meta?: any[];
  lang?: string;
  canonical?: string;
  image?: string;
  type?: 'article';
  category?: string;
  tags?: string[];
  date?: string;
  path: string;
}

function SEO({
  description,
  lang,
  meta,
  title,
  canonical,
  image,
  type,
  tags,
  date,
  path,
}: SEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  title = title || site.siteMetadata.title;
  image = image || site.siteMetadata.image;

  return (
    <>
      <GatsbySeo
        {...meta}
        title={title}
        language={lang}
        description={metaDescription}
        canonical={canonical || `${site.siteMetadata.siteUrl}${path}`}
        openGraph={{
          title,
          description: metaDescription,
          type: 'website',
          images: image
            ? [
                {
                  url: image,
                },
              ]
            : [],
          url: `${site.siteMetadata.siteUrl}${path}`,
          site_name: 'Ozan Tunca',
        }}
        twitter={{
          handle: 'tuncaozan',
          cardType: 'summary_large_image',
        }}
      />

      {type === 'article' && (
        <BlogPostJsonLd
          url={`${site.siteMetadata.siteUrl}${path}`}
          headline={title}
          images={[`${site.siteMetadata.siteUrl}${image}`]}
          datePublished={date || '2020-12-23 08:00:00'}
          dateCreated={date}
          dateModified={date}
          description={metaDescription}
          keywords={tags?.join() || []}
          authorName={site.siteMetadata.author}
          publisherName={site.siteMetadata.author}
        />
      )}

      <SocialProfileJsonLd
        type="Person"
        name={site.siteMetadata.author}
        url={site.siteMetadata.siteUrl}
        sameAs={[
          'https://www.instagram.com/ozan_tunca',
          'https://github.com/ozantunca',
          'https://linkedin.com/in/ozantunca',
          'https://medium.com/@ozantunca',
          'https://twitter.com/tuncaozan',
          'https://soundcloud.com/ozantunca',
        ]}
      />

      <Helmet
        link={[
          {
            rel: 'preconnect',
            href: 'https://www.google-analytics.com',
          },
        ]}
      />
    </>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
