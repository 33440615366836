import styled from 'styled-components';

export const AuthorImg = styled.figure`
  img {
    border-radius: 50%;
    border: 15px solid #fff;
  }
`;
export const SocialWrap = styled.div`
  margin-top: auto;
  padding-bottom: 20px;
`;
