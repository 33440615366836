import React from 'react';
import {
  FaInstagram,
  FaSoundcloud,
  FaGithub,
  FaMedium,
  FaLinkedin,
  FaTwitter,
} from 'react-icons/fa';
import Social, { SocialLink } from '../../shared/social';

const SocialOne = ({ social, ...restProps }) => {
  const { soundcloud, instagram, linkedin, twitter, medium, github } = social;
  return (
    <Social {...restProps}>
      {github && (
        <SocialLink path={github} aria-label="My GitHub Profile">
          <FaGithub />
        </SocialLink>
      )}
      {linkedin && (
        <SocialLink path={linkedin} aria-label="My LinkedIn Profile">
          <FaLinkedin />
        </SocialLink>
      )}
      {medium && (
        <SocialLink path={medium} aria-label="My Medium Profile">
          <FaMedium />
        </SocialLink>
      )}
      {instagram && (
        <SocialLink path={instagram} aria-label="My Instagram Profile">
          <FaInstagram />
        </SocialLink>
      )}
      {twitter && (
        <SocialLink path={twitter} aria-label="My Twitter Profile">
          <FaTwitter />
        </SocialLink>
      )}
      {soundcloud && (
        <SocialLink path={soundcloud} aria-label="My SoundCloud Profile">
          <FaSoundcloud />
        </SocialLink>
      )}
    </Social>
  );
};

SocialOne.defaultProps = {
  width: '38px',
  height: '38px',
  lineHeight: '32px',
  borderWidth: '2px',
  layout: 2,
};

export default SocialOne;
