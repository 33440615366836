import React from 'react';
import { LinkWrapper, AnchorTag } from './anchor.stc';

const Anchor: React.FC<{
  path: string;
}> = ({ path, children, ...props }) => {
  const internal = /^\/(?!\/)/.test(path);
  if (internal) {
    return (
      <LinkWrapper to={path} {...props}>
        {children}
      </LinkWrapper>
    );
  }
  return (
    <AnchorTag href={path} {...props}>
      {children}
    </AnchorTag>
  );
};

export default Anchor;
