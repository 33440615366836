import styled from 'styled-components';

export const CloseButtonWrap = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  .icon {
    width: 25px;
    height: 25px;
  }
`;
