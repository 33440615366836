import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import LogoImg from '../../../assets/img/logo.png';
import Social from '../../../components/socials/layout-two';
import Anchor from '../../../components/shared/anchor';
import styles from './Footer.module.scss';

const Footer: React.FC<any> = (props) => {
  const FooterData = useStaticQuery(graphql`
    query FooterDataQuery {
      site {
        siteMetadata {
          contact {
            social {
              medium
              instagram
              linkedin
              twitter
              github
              soundcloud
            }
          }
        }
      }
    }
  `);
  const { social } = FooterData.site.siteMetadata.contact;
  const { socialStyle, hideInMobile } = props;
  return (
    <div>
      <Container>
        <div className={styles.footerTop}>
          <Row>
            <Col xs={12} sm={4} md={2} lg={2} {...hideInMobile}>
              <div className={styles.footerLinks}>
                <img src={LogoImg} alt="Footer Logo" />
              </div>
            </Col>
            <Col xs={12} sm={8} md={{ size: 4, offset: 3 }}>
              <div className={styles.footerLinks}>
                <Anchor path="/privacy-policy">Privacy Policy</Anchor>
                <Anchor path="/terms-and-conditions">
                  Terms and Conditions
                </Anchor>
              </div>
            </Col>
            <Col sm={12} md={3}>
              <div className={styles.footerLinks}>
                <Social social={social} {...socialStyle} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

Footer.defaultProps = {
  socialStyle: {
    pr: ['15px', null, null, '12px', '15px', '20px'],
    icon: {
      width: '24px',
      height: '24px',
    },
  },
};

export default Footer;
