import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import './custom.scss';
import theme from '../../theme';
import { GlobalCSS } from '../../assets/css/style';

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="wrapper">
        <GlobalCSS />
        {children}
      </div>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-45224042-1"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-45224042-1');
          `,
        }}
      ></script>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
